/**
 * @file Anything that can run on every page.
 */

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	import('../components/header/header').then(({ default: vHeader }) => { vHeader(); });
	import('../components/search-bar/search-bar').then(({ default: vSearchBar }) => { vSearchBar(); });
	import('../components/main-nav/main-nav').then(({ default: vMainNav }) => { vMainNav(); });
	import('../components/mobile-nav/mobile-nav').then(({ default: vMobileNav }) => { vMobileNav(); });
	import('../components/footer-newsletter/footer-newsletter').then(({ default: vFooterNewsletter }) => { vFooterNewsletter(); });

	if (document.querySelector('.v-slider')) {
		import('../components/slider/slider')
			.then(({ default: vSlider }) => { vSlider(); });
	}

	if (document.querySelectorAll('.v-featured-stories__item').length > 1) {
		import('../components/featured-stories/featured-stories')
			.then(({ default: vFeaturedStories }) => { vFeaturedStories(); });
	}

	if (document.querySelectorAll('.v-video-lightbox-trigger').length || wpPostData.pageTemplate === 'template-media-center.php') {
		import('../components/video-lightbox/video-lightbox')
			.then(({ default: vVideoLightbox }) => { vVideoLightbox(); });
	}

	if (document.querySelector('.v-section-nav')) {
		import('../components/section-nav/section-nav')
			.then(({ default: vSectionNav }) => { vSectionNav(); });
	}

	if (document.querySelector('.v-filter-bar')) {
		import('../components/filter-bar/filter-bar')
			.then(({ default: vFilterBar }) => { vFilterBar(); });
	}

	/* if (document.querySelector('.v-program-list')) {
		import('../components/program-list/program-list')
			.then(({ default: vProgramList }) => { vProgramList(); });
	} */

	if (document.querySelectorAll('.v-accordions').length) {
		import('../components/accordions/accordions')
			.then(({ default: vAccordions }) => { vAccordions(); });
	}

	if (document.querySelectorAll('.v-embedded-form').length) {
		import('../components/embedded-form/embedded-form')
			.then(({ default: vEmbeddedForm }) => { vEmbeddedForm(); });
	}

	if (document.querySelector('.v-pagination .facetwp-type-pager')) {
		import('../components/pagination/pagination')
			.then(({ default: vPagination }) => { vPagination(); });
	}

	if (document.querySelector('.v-stories-grid')) {
		import('../components/stories-grid/stories-grid')
			.then(({ default: vStoriesGrid }) => { vStoriesGrid(); });
	}

	if (document.querySelector('.v-accordions-alt')) {
		import('../components/accordions-alt/accordions-alt')
			.then(({ default: vAccordionsAlt }) => { vAccordionsAlt(); });
	}

	if (document.querySelector('.v-event-details')) {
		import('../components/event-details/event-details')
			.then(({ default: vEventDetails }) => { vEventDetails(); });
	}

	const selects = document.querySelectorAll('select');
	[...selects].forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('v-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		const arrow   = document.createElement('span');
		wrapper.classList.add('v-form__select');
		arrow.classList.add('v-form__select-arrow');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
		wrapper.appendChild(arrow);
		select.classList.add('v-form__select-field');
	});

	const checkboxes = document.querySelectorAll('[type="checkbox"]');
	[...checkboxes].forEach((checkbox) => {
		const checkboxParent = checkbox.parentNode;
		if (checkboxParent.classList.contains('v-form__checkbox-label')) {
			return;
		}

		const box = document.createElement('span');
		box.classList.add('v-form__checkbox');
		checkboxParent.insertBefore(box, checkbox.nextSibling);
		checkbox.classList.add('v-form__checkbox-original');
		checkboxParent.classList.add('v-form__checkbox-label');
	});

	const dataLayer = window.dataLayer || [];
	window.addEventListener('message', (e) => {
		if (
			e.origin !== window.location.origin
			|| e.data !== 'newsletter-signup'
		) {
			return;
		}

		dataLayer.push({
			event: 'newsletter-signup',
		});
	});

	const cf7Forms = document.querySelectorAll('.wpcf7');
	cf7Forms.forEach((form) => {
		form.addEventListener('wpcf7mailsent', (event) => {
			dataLayer.push({
				event:  'form-submission',
				formId: event.detail.contactFormId,
			});
		});
	});

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import(/* webpackExports: "listen" */ 'quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}
